<template>
  <section id="join">
    <div class="contents_wrap">
      <div class="small_title_wrap">EFS 회원가입 <span class="f_s mgl10">기업회원 대상으로 가입 가능합니다.</span></div>
      <ul class="filter_list">
        <li>
          <dl>
            <dt>사업자 번호 <span class="forg">*</span></dt>
            <dd>
              <input
                  ref="corporateRegNoRef"
                  class="k-textbox"
                  :disabled="!!userInfo.cmpyCd"
                  v-model.trim="userInfo.corporateRegNo"
                  @input="() => {userInfo.corporateRegNo = userInfo.corporateRegNo.replace(/[^0-9]/g, '')}"
                  style="width: 65%"
                  placeholder=" - 제외한 10자리"
                  :maxlength="10"
              />
              <div class="join_input_btn">
                <button @click="onClickValidatorCorporateRegNo"
                        :disabled="!!userInfo.cmpyCd"
                        class="mid_btn">조회
                </button>
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>아이디 <span class="forg">*</span></dt>
            <dd>
              <input
                  ref="userIdRef"
                  class="k-textbox"
                  v-model.trim="userInfo.userId"
                  style="width: 60%"
                  :maxlength="30"
              />
              <div class="join_input_btn">
                <button
                    class="mid_btn"
                    :disabled="isDisabledCheck || !userInfo.userId"
                    @click="checkUserId">중복체크
                </button>
              </div>
            </dd>
          </dl>
         
        </li>
        <li>
          <dl>
            <dt>비밀번호 입력 <span class="forg">*</span></dt>
            <dd>
              <input
                  ref="userPwdRef"
                  type="password"
                  class="k-textbox"
                  :style="'width: 100%;'"
                  v-model.trim="userPwd"/>
              <div v-show="pwdValidator" class="join_msg"><span class="msg_red">규칙을 다시 확인해주세요.</span></div>
            </dd>
          </dl>
          <dl>
            <dt>비밀번호 재확인 <span class="forg">*</span></dt>
            <dd>
              <input
                  ref="userPwdConfirmRef"
                  type="password"
                  class="k-textbox"
                  :style="'width: 100%;'"
                  v-model.trim="userPwdConfirm"/>
              <div v-show="userPwdConfirm ? userPwd !== userPwdConfirm : false" class="join_msg">
                <span class="msg_red">입력한값이 일치하지 않습니다.</span>
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>성명 <span class="forg">*</span></dt>
            <dd>
              <input
                  ref="userNmRef"
                  class="k-textbox w100"
                  v-model.trim="userInfo.userNm"/>
            </dd>
          </dl>
           <dl>
            <dt>이메일주소<span class="forg">*</span></dt>
            <dd>
              <input
                  ref="emailRef"
                  class="k-textbox"
                  style="width: 100%"
                  v-model.trim="userInfo.email"
                  type="email"
              />
            </dd>
            <div class="join_msg">
              <span v-show="!!userInfo.email"></span>
            </div>
          </dl>
        </li>
        <li>
          <dl>
            <dt>부서명</dt>
            <dd>
              <input
                  ref="deptNmRef"
                  class="k-textbox"
                  style="width: 100%"
                  v-model.trim="userInfo.deptNm"
                  :maxlength="15"
              />
            </dd>
          </dl>
          <dl>
            <dt>유선전화 </dt>
            <dd>
              <input
                  ref="workTelNoRef"
                  class="k-textbox"
                  style="width: 100%"
                  v-model.trim="userInfo.workTelNo"
                  @input="() => {userInfo.workTelNo = userInfo.workTelNo.replace(/[^0-9]/g, '')}"
                  :maxlength="11"
              />
            </dd>
          </dl>
        </li>
        
        <li>
          <dl>
            <dt>휴대전화<span class="forg"> *</span></dt>
            <dd>
              <input
                  ref="spTelNoRef"
                  class="k-textbox"
                  style="width: 50%"
                  :disabled="isPassSms"
                  placeholder="( - 제외한 11자리)"
                  v-model.trim="userInfo.spTelNo"
                  @input="() => {userInfo.spTelNo = userInfo.spTelNo.replace(/[^0-9]/g, '')}"
                  :maxlength="11"
              />
              <div class="join_input_btn">
                <button @click="sendAuth"
                        :disabled="isPassSms"
                        class="mid_btn">인증번호 발송</button>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>SMS인증</dt>
            <dd>
              <input
                  ref="authNumberRef"
                  class="k-textbox"
                  style="width: 50%"
                  placeholder="인증번호 4자리"
                  v-model.trim="authNumber"
                  :disabled="isPassSms"
                  @input="() => {authNumber = authNumber.replace(/[^0-9]/g, '')}"
                  :maxlength="4"
              />
              <div class="join_input_btn">
                <button @click="onSubmit"
                        :disabled="isPassSms"
                        class="mid_btn">인증번호 인증</button>
              </div>
              <div class="join_msg">
                <span v-show="isCheckSms && isPassSms" class="msg_green">유효한 인증번호입니다.</span>
                <span v-show="isCheckSms && !isPassSms" class="msg_red">인증번호가 일치하지 않습니다.</span>
                <span v-show="!isPassSms" id="leftTime"></span>
              </div>
            </dd>
          </dl>
        </li>

      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">이용 메뉴</div>
      <div class="area_menu">
        <ul>
          <li v-for="menuItem in menuList"
              @click="$set(userInfo, 'svcAuthCd', menuItem.authCd)"
              :class="userInfo.svcAuthCd === menuItem.authCd ? ' on' : ''">
            <h1>{{menuItem.svcAuthNm}}</h1>
            <h2 v-for="subMenuItem in menuItem.commonJoinUserMenuDetailResponseList">{{ subMenuItem.menuNm}}</h2>
          </li>
        </ul>
      </div>
    </div>

    <div class="join_agree_wrap">
      <div class="small_title_wrap">EFS 개인정보처리방침에 동의해 주세요.</div>
      <div class="agree_allcheck">
        <div class="agree_check">
          <label>
            <input  type="checkbox"
                    id="agree_all"
                    class="k-checkbox"
                    v-model.trim="agrAll"
                    @change="e => { efsAgrDt = psiAgrDt = mktAgrAll = mktEmailAgrDt = mktSmsAgrDt = e.target.checked }">
            <label class="k-checkbox-label" for="agree_all"></label>
          </label>
        </div>
        <div class="allcheck_tit">
          모두 동의합니다.
        </div>
        <div class="allcheck_txt">
          전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. <br />
          선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다.
        </div>
      </div>
      <div class="agree_list w100">
        <ul class="">
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input  type="checkbox"
                        ref="efsAgrRef"
                        id="agree01"
                        name="agree01"
                        class="k-checkbox"
                        v-model.trim="efsAgrDt" />
                <label class="k-checkbox-label" for="agree01"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('service')" style="cursor: pointer;">[필수] EFS 이용약관 </a>
              <a @click="popup('service')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
          </li>
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input  type="checkbox"
                        ref="psiAgrRef"
                        id="agree02"
                        name="agree02"
                        class="k-checkbox"
                        v-model.trim="psiAgrDt" />
                <label class="k-checkbox-label" for="agree02"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('privacy')" style="cursor: pointer;">[필수] 개인정보 활용 동의</a>
              <a @click="popup('privacy')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
          </li>
          <li class="agree_tit">
            <div class="agree_check">
              <label>
                <input type="checkbox" id="agree03" class="k-checkbox" v-model.trim="mktAgrAll" @change="e => { mktEmailAgrDt = mktSmsAgrDt = e.target.checked }">
                <label class="k-checkbox-label" for="agree03"></label>
              </label>
            </div>
            <div class="agree_tit_txt">
              <a @click="popup('marketing')" style="cursor: pointer;">[선택] 마케팅 활용 동의</a>
              <a @click="popup('marketing')" style="cursor: pointer;"><span class="k-icon k-i-arrow-chevron-right"></span></a>
            </div>
            <div class="agree_option">
              <div class="agree_check">
                <label>
                  <input type="checkbox" id="agree04" class="k-checkbox" v-model.trim="mktEmailAgrDt">
                  <label class="k-checkbox-label" for="agree04"></label>
                </label>
              </div>
              <div class="agree_tit_txt">
                <a href="#">E-mail</a>
              </div>
            </div>
            <div class="agree_option">
              <div class="agree_check">
                <label>
                  <input type="checkbox" id="agree05" class="k-checkbox" v-model.trim="mktSmsAgrDt">
                  <label class="k-checkbox-label" for="agree05"></label>
                </label>
              </div>
              <div class="agree_tit_txt">
                <a href="#">SMS</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="button_area w30">
      <ul>
        <li class="w50">
          <button @click="$router.go(-1)" type="button" class="large_btn">이전</button>
        </li>
        <li class="w50">
          <button @click="onClickJoin" type="button" class="large_btn orange">회원가입하기</button>
        </li>
      </ul>
    </div>
    <kendo-window
        ref="addCmpyWindowRef"
        :title="'회사 등록'"
        :modal="true"
        :width="'700px'"
        style="display:none;"
        @open="e=>{this.isAddCmpyWindowOpen = true}"
        @close="e=>{this.isAddCmpyWindowOpen = false}"
    >
      <div class="pop-container">
        <div class="pop-conts">
          <table class="basic_table mgt10" border="0" cellpadding="0" cellspacing="0">
            <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup>
            <tbody role="rowgroup">
            <tr role="row">
              <th>사업자 번호<span class="forg mgl5">*</span></th>
              <td class="txt_l" colspan="3">
                {{ userInfo.corporateRegNo }}
              </td>

            </tr>
            <tr role="row">
              <th>상호명<span class="forg mgl5">*</span></th>
              <td class="txt_l" colspan="3">
                <input
                    ref="cmpyNmRef"
                    class="k-textbox"
                    v-model.trim="cmpyInfo.cmpyNm"
                    :placeholder="'사업자등록증 상의 상호명을 입력해주세요.'"
                />
              </td>
            </tr>
            <tr>
              <th>대표자명</th>
              <td class="txt_l" colspan="3">
                <input
                    ref="ceoNmRef"
                    class="k-textbox"
                    v-model.trim="cmpyInfo.ceoNm"
                    :placeholder="'대표자명'"
                />
              </td>
            </tr>
            <tr role="row">
              <th>주소<span class="forg mgl5">*</span></th>
              <td class="txt_l" colspan="3">
                <button @click="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()" class="mid_btn orange w20 f_r">검색</button>
                <input
                    ref="zipNoRef"
                    class="k-textbox mr5"
                    style="width: 18%"
                    placeholder="우편번호"
                    v-model.trim="cmpyInfo.zipNo"
                    @focus="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()"
                />
                <input
                    ref="addrRef"
                    class="k-textbox w60"
                    v-model.trim="cmpyInfo.addr"
                    :placeholder="'주소'"
                    @focus="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()"
                />
                <div class="mt5">
                  <input
                      ref="addrDtlRef"
                      class="k-textbox w100"
                      v-model.trim="cmpyInfo.addrDtl"
                      :placeholder="'상세주소'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>대표번호</th>
              <td class="txt_l" colspan="3">
                <input
                    ref="cmpyMainTelNoRef"
                    class="k-textbox w100"
                    placeholder="대표번호"
                    v-model.trim="cmpyInfo.cmpyMainTelNo"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <div class="button_area w60">
            <ul>
              <li><button @click="$refs.addCmpyWindowRef.kendoWidget().close()" class="large_btn">닫기</button></li>
              <li><button @click="saveCmpy" class="large_btn orange ml5"> 저장 </button></li>
            </ul>
          </div>
        </div>
      </div>
    </kendo-window>

    <kendo-window
        ref="selectCmpyWindowRef"
        :title="'회사 선택'"
        :modal="true"
        :width="'700px'"
        style="display:none;"
        @open="e=>{this.isSelectCmpyWindowOpen = true}"
        @close="e=>{this.isSelectCmpyWindowOpen = false}"
    >
      <kendo-grid ref="gridRef"
                  style="cursor: pointer; height: 368px"
                  :data-source="searchCmpyList"
                  :navigatable="false"
                  :sortable-mode="'multiple'"
                  :reorderable="true"
                  :column-menu="false"
                  :resizable="true"
                  :selectable="'row'"
                  :columns="gridColumns"
                  :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                  @change="ev=>{
                    selectCmpy = ev.sender.dataItem(ev.sender.select())
                    isPassCorporateRegNo = true
                  }"
      ></kendo-grid>
      <div class="button_area w100">
        <ul>
          <li class="w30">
            <button @click="updateUserCmpyCd"
                    class="large_btn orange">완료
            </button>
          </li>
        </ul>
      </div>
    </kendo-window>

    <kendo-window
        ref="completeJoinWindowRef"
        :title="'회원가입 안내'"
        :modal="true"
        :width="'400px'"
        style="display:none;">
      <div class="txt_c">
        <img src="../../assets/img/etners-main/im_ic_01.png" class="mt5" alt="완료아이콘" />
        <h2 class="mgt20">회원가입을 환영합니다.</h2>
        <div class="gray mgt10">
        입력하신 아이디, 패스워드로 로그인해주세요.<br>
        소속중인 기업에서 EFS를 사용중인 경우,<br>
        운영시스템의 접근 권한은 별도 문의하시기 바랍니다.</div>
        
        <div class="button_area w100">
          <ul>
             <li>
              <button @click="goContactUs" class="large_btn mgl5">권한 문의</button>
            </li>
            <li>
               <button @click="$router.push({name : 'Login'})" class="large_btn orange ml5">로그인</button>
            </li>
          </ul>
        </div>
        <br>
        <br>
      </div>
    </kendo-window>
    <!-- 주소검색 window -->
    <daum-postcode-window ref="postcodeWindowRef"
                          @select-addr="data=>{
                            cmpyInfo = {
                              ...cmpyInfo,
                              zipNo : data.zipCode,
                              addr : data.addr
                            }
                          }"
    ></daum-postcode-window>
  </section>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import Constant from '@/common/constant'

export default {
  name: 'Join',
  components: {
    'DaumPostcodeWindow': () => import('/src/components/DaumPostcodeWindow.vue'),
  },
  computed : {
    pwdValidator : function (){
      if(!this.userPwd) return false
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(this.userPwd)
      return !matched
    },
    isDisabledCheck : function (){
      const vm = this
      return vm.userInfo.userId === vm.initUserId
    },
  },
  created () {
    this.getMenuList()
  },
  methods : {
    sendAuth () {
      const vm = this

      if(!vm.userInfo.spTelNo.trim()) {
        kendo.alert('전화번호를 입력해 주세요.').bind('close', function() { vm.$refs.emailRef.focus() })
        return false
      }
      if(vm.userInfo.spTelNo.length < 11) {
        kendo.alert('전화번호 11자리를 입력해주세요.').bind('close', function() { vm.$refs.emailRef.focus() })
        return false
      }

      ApiUtil.post(`${ApiConfig.efsDomain}/efs/join/send-auth-number`, { spTelNo : vm.userInfo.spTelNo }).then(response => {

        if(EtnersCommonUtil.isEmpty(response.data.resultData.authNo)) {
          kendo.alert('알 수 없는 오류가 발생하였습니다.<br>관리자에게 문의해주세요.')
          return false
        }

        kendo.alert('인증번호가 전송되었습니다.<br>유효시간은 2분 30초입니다.')

        // 남은 시간
        let leftSec = 150,
            display = document.querySelector('#leftTime')
        // 이미 타이머가 작동중이면 중지
        if (this.isTimerRunning) {
          clearInterval(this.leftTimer)
        }
        // 인증 유효시간 출력
        this.showLeftTime(leftSec, display)
        // data setting
        this.authNumberComfirmBoolean = false
        this.authNumber = null
        this.authNumberServer = response.data.resultData.authNo
        this.authNumberComfirmMsg = '<span class="msg_green"></span>'
      })
    },
    showLeftTime (count, display) {
      const vm = this
      let minutes, seconds
      this.isTimerRunning = true
      this.leftTimer = setInterval(function () {
        minutes = parseInt(count / 60, 10)
        seconds = parseInt(count % 60, 10)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds

        if (!vm.authNumberComfirmBoolean) {
          display.textContent = '\xa0\xa0\xa0' + minutes + ':' + seconds
          if (--count < 0) {
            clearInterval(vm.leftTimer)
            display.textContent = '시간초과'
            display.style.color = '#ff0000'
            this.isTimerRunning = false
            this.authNumberServer = null
            return false
          }
        } else {
          clearInterval(vm.leftTimer)
          display.textContent = ''
          this.isTimerRunning = false
          return false
        }
      }, 1000)
    },
    // 인증 확인
    onSubmit () {
      const vm = this

      if(!vm.authNumber) {
        kendo.alert('인증번호 4자리를 입력해 주세요').bind('close', function() {
          vm.$refs.authNumberRef.focus()
        })
        return false
      }
      if(EtnersCommonUtil.isEmpty(this.authNumberServer)) {
        kendo.alert('인증번호가 만료되었거나 발급되지 않았습니다.').bind('close', function() { 
          vm.$refs.authNumberRef.focus() 
        })
        return false
      }

      vm.isPassSms = vm.authNumber == this.authNumberServer;
      vm.isCheckSms = true
    },
    onClickValidatorCorporateRegNo : function () {
      const vm = this
      let param = {
        corporateRegNo : vm.userInfo.corporateRegNo
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/join/check-company`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        let isNotExist = response.data.resultData
        if(isNotExist){
          kendo.confirm('등록되지 않은 회사입니다.<br>회사를 등록을 진행하시겠습니까?').done(
              function () {
                vm.$refs.addCmpyWindowRef.kendoWidget().center().open()
              })
        }else{
          vm.searchCmpyListByCorRegNo()
        }
      })
    },
    searchCmpyListByCorRegNo : function (){
      const vm = this
      let param = {
        requestType : 'EXPORT',
        searchCorporateRegNo : vm.userInfo.corporateRegNo
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/join/search-company`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        if(response.data.resultData.length > 1){
          // 회사가 여러개인경우
          vm.$refs.selectCmpyWindowRef.kendoWidget().center().open()
          vm.searchCmpyList = EtnersCommonUtil.setRowNumber(response.data.resultData)
        }else if(response.data.resultData.length === 1){
          // 회사가 한개인 경우
          kendo.alert("조회가 완료되었습니다.")
          vm.isPassCorporateRegNo = true
          vm.$set(vm.userInfo, 'cmpyCd', response.data.resultData[0].cmpyCd)
        }
      })
    },
    getMenuList : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/join/user-menu`)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }
        response.data.resultData.forEach(item=>{
          switch (item.authCd){
            case Constant.efs.auth.LOGISTICS_MANAGER :
              item.svcAuthNm = '메뉴B'
              break
            case Constant.efs.auth.CLIENT_USER :
              item.svcAuthNm = '메뉴A'
              break
            case Constant.efs.auth.OPERATION_MANAGER :
              item.svcAuthNm = '메뉴C'
              break
          }
        })
        vm.menuList = response.data.resultData
      })
    },
    popup: function (fileNm) {
      window.open('http://www.etners.com/privacy/etnersefs/fms/' + fileNm + '.html', fileNm)
    },
    checkCorporateRegNo : function (){

    },
    goContactUs : function (){
      window.location.href='/landing?num=8'
    },
    onClickJoin : function () {
      const vm = this
      if(vm.validator()){
        vm.saveJoinUserInfo()
      }
    },
    saveJoinUserInfo : function (){
      const vm = this

      let param = {
        efsAgrDt : vm.efsAgrDt,
        psiAgrDt : vm.psiAgrDt,
        userPwd : vm.userPwd,
        mktEmailAgrDt : vm.mktEmailAgrDt,
        mktSmsAgrDt : vm.mktSmsAgrDt,
        ...vm.userInfo
      }
      ApiUtil.post(`${ApiConfig.efsDomain}/efs/join/user`, param).then(response => {

        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }
        if(!!response.data.resultData.unqUserId){
          vm.$refs.completeJoinWindowRef.kendoWidget().center().open()
        }else {
          kendo.alert("회원가입이 정상적으로 처리되지않았습니다.")
        }
      })
    },
    updateUserCmpyCd : function (){
      const vm = this
      vm.$set(vm.userInfo, 'cmpyCd', vm.selectCmpy.cmpyCd)
      vm.$refs.selectCmpyWindowRef.kendoWidget().close()
    },
    checkUserId : function (){
      const vm = this

      if(vm.userInfo?.userId?.length < 4){
        kendo.alert('아이디를 4자리 이상 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.userIdRef)
        })

        function focusing(target) {
          if(target) {
            setTimeout(function() {
              target.focus()
            }, 100)
          }
        }
        return false
      }

      let param = {
        userId : vm.userInfo.userId,
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/join/check-user`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        let isPass = response.data.resultData
        if(isPass){
          kendo.alert('입력하신 ID로 등록이 가능합니다.')
          vm.isPassUserId = isPass
          vm.initUserId = vm.userInfo.userId
        }else{
          kendo.alert('중복된 ID가 있습니다.')
          vm.isPassUserId = !isPass
        }
      })
    },
    saveCmpy : function(){
      const vm = this

      if(saveCmpyValidator()){

        ApiUtil.post(`${ApiConfig.efsDomain}/efs/join/company`,
            { corporateRegNo : vm.userInfo.corporateRegNo, ...vm.cmpyInfo})
        .then(response => {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          if(!!response.data.resultData.cmpyCd){
            vm.$set(vm.userInfo, 'cmpyCd', response.data.resultData.cmpyCd)
            vm.isPassCorporateRegNo=true
            vm.$refs.addCmpyWindowRef.kendoWidget().close()
          }else {
            kendo.alert('회사등록이 정상적으로 처리되지 않았습니다.')
            vm.isPassCorporateRegNo=false
            vm.userInfo.cmpyCd = null
          }
        })
      }

      function saveCmpyValidator () {

        if(!vm.cmpyInfo.cmpyNm) {
          kendo.alert('상호명을 입력해 주세요.').bind('close', function() {
            focusing(vm.$refs.cmpyNmRef)
          })
          return false
        }

        if(!vm.cmpyInfo.addr) {
          kendo.alert('주소를 입력해 주세요.').bind('close', function() {
            focusing(vm.$refs.addrRef)
          })
          return false
        }

        return true

      }

      function focusing(target) {
        if(target) {
          setTimeout(function() {
            target.focus()
          }, 100)
        }
      }
    },
    validator : function () {
      const vm = this

      if(!vm.userInfo.corporateRegNo){
        kendo.alert('사업자등록번호를 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.corporateRegNoRef)
        })
        return false
      }
      if(!vm.isPassCorporateRegNo){
        kendo.alert('사업자등록번호를 조회해주세요.').bind('close', function() {
          focusing(vm.$refs.corporateRegNoRef)
        })
        return false
      }
      if(!vm.userInfo.userId){
        kendo.alert('아이디를 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.userIdRef)
        })
        return false
      }
      if(vm.userInfo?.userId?.length < 4){
        kendo.alert('아이디를 4자리 이상 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.userIdRef)
        })
        return false
      }
      // 중복체크를 안한경우
      if(!vm.isPassUserId){
        kendo.alert("ID의 중복체크를 진행해주세요.").bind('close', function() {
          focusing(vm.$refs.userIdRef)
        })
        return
      }
      // 중복체크 후 ID를 다시 바꾼경우
      if(vm.userInfo.userId !== vm.initUserId){
        kendo.alert("ID의 중복체크를 진행해주세요.").bind('close', function() {
          focusing(vm.$refs.userIdRef)
        })
      }
      if(!vm.userPwd) {
        kendo.alert('비밀번호를 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdRef)
        })
        return false
      }
      if (vm.pwdValidator) {
        kendo.alert('비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdRef)
        })
        return false
      }
      if(!vm.userPwdConfirm) {
        kendo.alert('비밀번호재확인을 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdConfirmRef)
        })
        return false
      }
      if (vm.userPwd!== vm.userPwdConfirm) {
        kendo.alert('새 비밀번호와 맞지 않습니다.<br>기재한 비밀번호를 확인해주세요.').bind('close', function() {
          focusing(vm.$refs.userPwdConfirmRef.kendoWidget().element)
        })
        return false
      }
      if(!vm.userInfo.userNm) {
        kendo.alert('성명을 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.userNmRef)
        })
        return false
      }
      if(!vm.userInfo.email) {
        kendo.alert('이메일주소를 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.emailRef)
        })
        return false
      }
      if(!EtnersCommonUtil.validCheckEmail(vm.userInfo.email)) {
        kendo.alert('이메일주소 형식에 맞게 입력해주세요.').bind('close', function() {
          focusing(vm.$refs.emailRef)
        })
        return false
      }
      if(!vm.userInfo.spTelNo) {
        kendo.alert('휴대전화번호를 입력해 주세요.').bind('close', function() {
          focusing(vm.$refs.spTelNoRef)
        })
        return false
      }
      if(!vm.isPassSms){
        kendo.alert('휴대전화 인증을 완료해주세요').bind('close', function() {
          focusing(vm.$refs.spTelNoRef)
        })
        return false
      }
      if(!vm.userInfo.svcAuthCd){
        kendo.alert('이용하실 메뉴를 선택해주세요..')
        return false
      }
      if(!vm.efsAgrDt) {
        kendo.alert('EFS 이용약관에 동의해 주세요.').bind('close', function() {
          focusing(vm.$refs.efsAgrRef)
        })
        return false
      }
      if(!vm.psiAgrDt) {
        kendo.alert('개인정보 활용 동의해 주세요.').bind('close', function() {
          focusing(vm.$refs.psiAgrRef)
        })
        return false
      }

      function focusing(target) {
        if(target) {
          setTimeout(function() {
            target.focus()
          }, 100)
        }
      }

      return true

    },
  },
  data (){
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      ApiConfig,
      userPwd : null,
      userPwdConfirm : null,
      agrAll: false,
      efsAgrDt: false,
      psiAgrDt: false,
      mktEmailAgrDt: false,
      mktSmsAgrDt: false,
      mktAgrAll: false,
      selectCmpy : '',
      isPassCorporateRegNo : false,
      isPassUserId : false,
      initUserId : '',
      isAddCmpyWindowOpen : false,
      isSelectCmpyWindowOpen : false,
      isCheckSms : false,
      isPassSms : false,
      authNumberComfirmBoolean : false,
      authNumber : '',
      authNumberServer : null,
      authNumberComfirmMsg: '<span class="msg_green"></span>',
      leftTimer: null,
      isTimerRunning: false,
      cmpyInfo : {},
      userInfo : {},
      searchCmpyList : [],
      menuList : [],
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'corporateRegNo',
          title: '사업자번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>
